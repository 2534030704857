/**
 * Constantes de la aplicación
 */
export class Constants {
  /** url para obtener información del usuario */
  static seguridadUsuarioInfo = 'seguridad/usuario';

  /** url para concer si un usuario tiene permiso hacia un recurso */
  static seguridadUsuarioAcceso = 'seguridad/acceso';

  /** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
   *  obtener el html del formulario de login
   */
  static seguridadVerificarEstado = 'seguridad/estado';

  /** url para saber si un usuario es administrador o no
   */
  static urlAdministrador = 'USUARIO_ADMINISTRADOR';

  // gen_constants
}
