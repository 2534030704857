<!-- top navbar-->
<app-header *ngIf="user" [user]="user"></app-header>
<div class="container" [ngClass]="{'header-menu-active': menuActive}">
      <!-- sidebar -->
      <app-sidebar class="aside" *ngIf="user" [userMenu]="user!.menu"></app-sidebar>
      <!-- Page content-->
      <div class="app-content-wrapper" (click)="this.sidebarService.setStatus(false)">
            <router-outlet></router-outlet>
            <!-- Page footer-->
            <app-footer></app-footer>
      </div>
      <!-- Page Mask-->
      <div class="layout-mask"></div>
</div>