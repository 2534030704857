import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { Error403Component } from '../shared/global-error-components';
import { PublicLayoutComponent } from '@public-layout/public-layout.component';

/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [
  {
    path: 'adminlistasmaestras',
    component: PublicLayoutComponent,
    children: [
      { path: '', redirectTo: 'public', pathMatch: 'full' },
      {
        path: 'public',
        loadChildren: () =>
          import('@listasmaestras-public/listasmaestras-public.module').then(
            (m) => m.ListasmaestrasPublicModule
          )
      }
    ]
  },
  {
    path: 'adminlistasmaestras',
    component: LayoutComponent,
    children: [
      {
        path: 'private',
        loadChildren: () =>
          import('@listasmaestras/listasmaestras.module').then(
            (m) => m.ListasmaestrasModule
          )
      }
    ]
  },

  // errors
  { path: 'access-denied', component: Error403Component },

  // Not found
  { path: '**', redirectTo: 'adminlistasmaestras' }
];
