/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptRequestService implements HttpInterceptor {
  /**
   * Constructor de la clase
   */
  constructor() {}

  /**
   * intercepta las peticiones
   *
   * @param req petición
   * @param next handler para ejecutar la logica cuando se captura la petición
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqWithHeaders = req.clone({
      headers: new HttpHeaders({
        'X-APP-RELAYSTATE': location.href
      }),
      withCredentials: true
    });

    return next.handle(reqWithHeaders).pipe(
      catchError((err) => {
        if (err.status && err.status === 401) {
          document.write(err.error);
        }
        return throwError(err);
      })
    );
  }
}
