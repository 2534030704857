import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '../core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';
import { IdentityService, IdentityUser } from '@core/security';
import { MenuHeader } from '@core/sidebar';

/**
 * Componente padre para la renderización del Layout de la aplicación
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  /**
   * Entidad del usuario en el sistema
   */
  user?: IdentityUser = undefined;

  /**
   * Entidad del usuario en el sistema
   */
  menu?: MenuHeader = undefined;

  /**
   * Indica si el menú esta visible o no
   */
  public menuActive = false;

  /**
   * Subscription para obtener el estado del sidebar
   */
  private sidebarSuscription?: Subscription = undefined;

  /**
   * Constructor
   *
   * @param sidebarService Servicio sidebar
   */
  constructor(
    private identityService: IdentityService,
    public sidebarService: SidebarService
  ) {}

  /**
   * Inicialización, se carga el estado del sidebar para definir la clase que activa la animación cuando se abre o cierra el menú
   */
  ngOnInit(): void {
    this.sidebarSuscription = this.sidebarService.onStatusChanged(
      (status: boolean) => (this.menuActive = status)
    );
    this.identityService.getUserInfo().subscribe((userInfo) => {
      this.user = userInfo;
      this.menu = userInfo.menu;
    });
  }

  /**
   * Se libera recursos
   */
  ngOnDestroy(): void {
    if (this.sidebarSuscription) {
      this.sidebarSuscription.unsubscribe();
    }
  }
}
