<aside class="sidebar">
  <div class="p-fluid grid" style="margin-left: 10px">
    <div class="col-12 col-md-4 input" style="width: 70px;">
      <a>
        <img loading="lazy" width="70" height="5" src="./../../../assets/images/line_horizontal.png"
          alt="line_horizontal" />
      </a>
    </div>
  </div>
  <ul class="list">
    <li class="list__item" *ngFor="let menu of userMenu?.menuItems" [ngClass]="{'list__item_active': menu.active }">
      <hr color="#1D428A" width="90%" style="margin-left: 28px;"/>
      <a class="list__link" href="#"
        [routerLink]="menu.children &&  menu.children.length > 0 ? null : '/'.concat(menu.contextPath)"
        (click)="toggleSubmenu(menu)" [pTooltip]="menu.children.length > 0 ? '' : menu.name">
        <em [ngClass]="menu.active === true ? menu.image + '-fill' : menu.image"></em>
        {{ menu.name }}
      </a>
      <!-- <span class="list__link">{{ menu.name }}</span>      -->
      <app-submenu class="submenu" *ngIf="menu.children && menu.children.length > 0" [basePath]="menu.contextPath"
        [itemsMenu]="menu" [menuName]="menu.name" [completeMenu]="userMenu?.menuItems">
      </app-submenu>
    </li>
  </ul>
  <div class="p-fluid grid" style="margin-left: 10px; padding-bottom: 100px;">
    <div class="col-12 col-md-4 input" style="width: 70px;">
      <a>
        <img loading="lazy" width="70" height="5" src="./../../../assets/images/line_horizontal.png"
          alt="line_horizontal" />
      </a>
    </div>
  </div>
  <div class="p-fluid grid" style="margin-left: 15px; align-items: center;">
    <div class="col-2 col-md-4 input" style="width: 31px;">
      <a>
        <span style="color: #ffffff; font-size: x-large;" class="bi bi-info-circle mr-2"></span>
      </a>
    </div>   
  </div>
</aside>