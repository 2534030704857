// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Aqui se pueden definir variables que se cargan cuando se ejecuta la aplicacion en local o cuando se compila sin el comando buildpnd
 */
let xsrfToken = 'XSRF-TOKEN-DLLO';
let localBack = 'https://local.suranet.com:8080/adminlistasmaestrasapi/api/';
let logout =
  'https://seusdllo.dllosura.com/acs/logout?service=adminlistasmaestras';
if (location.href.includes('listasmaestrasapp.dllosura.com')) {
  xsrfToken = 'XSRF-TOKEN-DLLO';
  localBack =
    'https://listasmaestrasapi.dllosura.com/adminlistasmaestrasapi/api/';
  logout =
    'https://seusdllo.dllosura.com/acs/logout?service=adminlistasmaestras';
}
if (location.href.includes('listasmaestrasapp.labsura.com')) {
  xsrfToken = 'XSRF-TOKEN-LAB';
  localBack =
    'https://listasmaestrasapi.labsura.com/adminlistasmaestrasapi/api/';
  logout = 'https://seuslab.labsura.com/acs/logout?service=adminlistasmaestras';
}
if (location.href.includes('listasmaestrasapp.suramericana.com')) {
  xsrfToken = 'XSRF-TOKEN';
  localBack =
    'https://listasmaestrasapi.suramericana.com/adminlistasmaestrasapi/api/';
  logout = 'https://seus.suranet.com/acs/logout?service=adminlistasmaestras';
}

export const environment = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */
  production: false,
  xsrfToken,
  localBack,
  logout
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
